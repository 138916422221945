import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { Link, graphql } from 'gatsby'
import styled from 'styled-components'

import MainLayout from '../layouts'
import config from '../../config'
import PostsList from '../components/PostList'
import PostsListHeader from '../components/PostsListHeader'

const Category = ({ pageContext: { category }, data: { allMdx } }) => {
  const { nodes, totalCount } = allMdx
  const subline = `${totalCount} post${totalCount === 1 ? '' : 's'} tagged with "${category}"`
  return (
    <MainLayout>
      <Wrapper>
        <Helmet title={`Category: ${category} | ${config.siteTitle}`} />
      </Wrapper>
      <PostsListHeader>{category}</PostsListHeader>
      <PostsList posts={allMdx.nodes} />
    </MainLayout>
  )
}

const Wrapper = styled.div``

export default Category

Category.propTypes = {
  pageContext: PropTypes.shape({
    category: PropTypes.string.isRequired,
  }).isRequired,
  data: PropTypes.shape({
    allMdx: PropTypes.shape({
      nodes: PropTypes.array.isRequired,
      totalCount: PropTypes.number.isRequired,
    }),
  }).isRequired,
}

export const postQuery = graphql`
  query CategoryPageQuery($category: String!) {
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { categories: { eq: $category } } }
    ) {
      totalCount
      nodes {
        frontmatter {
          title
          date(formatString: "MM/DD/YYYY")
          categories
          cover {
            children {
              ... on ImageSharp {
                fluid(maxWidth: 1600, maxHeight: 1600) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        fields {
          slug
        }
        excerpt(pruneLength: 200)
        timeToRead
      }
    }
  }
`
