import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import Categories from '../Categories'

const Item = post => (
  <div>
    <Image to={post.slug}>
      <Img fluid={post.fluid} />
    </Image>
    <Categories categories={post.categories} />
    <Title>
      <Link to={post.slug}>{post.title}</Link>
    </Title>
    <Excerpt>{post.excerpt}</Excerpt>
  </div>
)

export default Item

const Image = styled(Link)`
  .gatsby-image-wrapper {
    border-radius: 1.111rem;
    background: 50% no-repeat;
    background-size: cover;

    @media (max-width: ${props => props.theme.breakpoints.phone}) {
      height: 15rem;
    }
  }
`
const Title = styled.h5`
  font-weight: 700;
  margin: 1.833rem 0 1rem 0;
`
const Excerpt = styled.p`
  line-height: 1.333rem;
`
