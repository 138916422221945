import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const Categories = ({ categories }) => (
  <Category>
    {categories.map(category => (
      <li key={category}>
        <Link to={`/categories/${category.toLowerCase()}`}>{category}</Link>
      </li>
    ))}
  </Category>
)

Categories.propTypes = {
  categories: PropTypes.array.isRequired,
}

export default Categories

const Category = styled.ul`
  font-size: 0.85rem;
  font-weight: 600;
  margin: 1.388rem 0px 0.8333rem 0px;
  padding: 0;

  li {
    /* background-color: #bed3ff; */
    padding: 0.2777rem 0.9rem;
    margin-right: 0.555rem;
    display: inline;
    border: 1px solid ${props => props.theme.colors.primary};
    border-radius: 10px;

    a {
      color: ${props => props.theme.colors.primary};
    }
  }
`
