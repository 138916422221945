import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Item from './Item'

const PostList = ({ posts }) => (
  <List>
    {posts.map(post => (
      <Item
        title={post.frontmatter.title}
        date={post.frontmatter.date}
        excerpt={post.excerpt}
        timeToRead={post.timeToRead}
        slug={post.fields.slug}
        categories={post.frontmatter.categories}
        fluid={post.frontmatter.cover.children[0].fluid}
        key={post.fields.slug}
      />
    ))}
  </List>
)

export default PostList

PostList.propTypes = {
  posts: PropTypes.array.isRequired,
}

const List = styled.div`
  width: 100%;
  display: grid;
  grid-auto-rows: 1fr;
  grid-template-columns: repeat(auto-fill, minmax(269px, 1fr));
  grid-gap: 2rem;

  @media (max-width: ${props => props.theme.tablet}) {
    grid-auto-rows: 150px;
  }
`
