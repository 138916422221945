import React from 'react'
import styled from 'styled-components'

const PostsListHeader = ({ children }) => (
  <Wrapper>
    <Header>{children}</Header>
  </Wrapper>
)

export default PostsListHeader

const Header = styled.h1`
  font-family: ${props => props.theme.fontFamily.serif};
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
`
